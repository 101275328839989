.App {
  text-align: center;
}

.main-title {
  font-size: 72px !important;
}

@media (max-width: 768px) {
  .main-title {
    font-size: 54px !important;
  }
}
